exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acme-sports-js": () => import("./../../../src/pages/acmeSports.js" /* webpackChunkName: "component---src-pages-acme-sports-js" */),
  "component---src-pages-contact-card-js": () => import("./../../../src/pages/contactCard.js" /* webpackChunkName: "component---src-pages-contact-card-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joke-js": () => import("./../../../src/pages/joke.js" /* webpackChunkName: "component---src-pages-joke-js" */),
  "component---src-pages-meme-gen-js": () => import("./../../../src/pages/memeGen.js" /* webpackChunkName: "component---src-pages-meme-gen-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-todo-js": () => import("./../../../src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */)
}

